import './App.css';
import Meditation from './pages/Meditation';
import Backendless from 'backendless';
const { REACT_APP_BACKENDLESS_SERVER_URL,REACT_APP_BACKENDLESS_APP_ID,REACT_APP_BACKENDLESS_API_KEY } = process.env;
Backendless.serverURL = REACT_APP_BACKENDLESS_SERVER_URL;
Backendless.initApp(REACT_APP_BACKENDLESS_APP_ID, REACT_APP_BACKENDLESS_API_KEY);

function App() {
  return (
    <Meditation />
  );
}

export default App;
