import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from "react";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
import "./styles.css";
import Bubbles from "../bubbles/Bubbles";
import VideoPlayer from "../videoplayer/VideoPlayer";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */

const AudioPlayer = forwardRef((props,ref) => {

  let mainTitle = props.mainTitle; 
  let description = props.description;
  let countdownPrompt = props.countdownPrompt;
  let tracks = props.tracks;
  let enableTrackInfo = props.enableTrackInfo;
  let enableProgress = props.enableProgress;
  let enableControls = props.enableControls;
  let autoStart = props.autoStart; 
  let isShowPlayer = props.isShowPlayer;
  let meetingInfo = props.meetingInfo;
  let mainClassName = props.mainClassName;
  let meetingStatus = props.meetingStatus;
  let enableCountdown = props.enableCountdown;
  let welcomePanel = props.welcomePanel;

  // State
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAutoStart] = useState(autoStart);
  const [videoURL, setVideoURL] = useState(null);
  const [enableBubbles, setEnableBubbles] = useState(true);
  const [enableControl, setEnableControl] = useState(enableControls);
  
  // Destructure for conciseness
  const { title, artist, color, image, audioSrc } = tracks[trackIndex];

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };
  

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    if (isPlaying) {
      startTimer();
    }
  };

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
    playAudioLater();
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
    playAudioLater();
  };

  const playAudioLater = () => {
    setTimeout(function(){
      if(isPlaying)
        audioRef.current.play().catch((e)=>{});
      },100)
  }

  useEffect(() => {
    if (autoStart) {
      setIsPlaying(true);
    }else{
      setIsPlaying(false);
    }
  },[autoStart]);


  useEffect(() => {
    if (isAutoStart) {
      setIsPlaying(true);
    }else{
      setIsPlaying(false);
    }
  },[isAutoStart]);

  useEffect(() => {
    if (isPlaying) {
      //playAudio();
      //audioRef.current.load();
      audioRef.current.play().catch((e)=>{});
      startTimer();
    } else {
      audioRef.current.pause();
    }
  });

  const playAudio = (canStop) => {
    if(isPlaying){
      if(canStop)
        setIsPlaying(false);
    }else{
      setIsPlaying(true);
      audioRef.current.play();
    }
  }

  



  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();
    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);
    //setIsPlaying(true);
    /*
    if (isReady.current) {
      //playAudio();
      setIsPlaying(true);
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
    */
  }, [trackIndex,audioSrc]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    onClickContinue  () {
      if(meetingStatus!=="STARTED" && meetingInfo.enableMusic && !meetingInfo.autoplaycontent)
        playAudio();
      //auto play video
      if(!isPlaying){
        setTimeout(function(){
        if(meetingInfo && meetingInfo.content && meetingInfo.autoplaycontent)
          playVideo();
        },100)
      }
      if(document.getElementById("video-icon"))
        setTimeout(function(){
          if(document.getElementById("video-icon"))
            document.getElementById("video-icon").classList.add("corner-position");      
        },10000);
    }
  }))

  const playVideo = () => {
    setVideoURL(meetingInfo.content);
    setIsPlaying(false);
    setEnableBubbles(false);
    setEnableControl(false);
    if(document.getElementById("video-icon"))
      document.getElementById("video-icon").style.display = "none"; 
  }

  const onStopVideo = () => {
    meetingInfo.autoplaycontent = false;
    setVideoURL(null);
    setEnableBubbles(true);
    setEnableControl(true);
    if(document.getElementById("video-icon"))
     document.getElementById("video-icon").style.display = "";
  }

  
  
  return (
    <div className={mainClassName}>
      {!isShowPlayer && welcomePanel}
      {<div>
        <div className="audio-player-holder" style={{display:isShowPlayer ?"block":"none"}}>
    <div className="audio-player">
      <div className="track-info">
        <h1 className="title">{mainTitle}</h1>
        <h2 className="artist description">{description}</h2>
        {countdownPrompt}
        {meetingInfo && meetingInfo.content && 
          <div>
          <div className="video-item" id="video-icon">
            <div className="video-icon" onClick={playVideo}></div>
            <div className="video-title" onClick={playVideo} title={meetingInfo.contenttitle}>{meetingInfo.contenttitle}</div>
          </div>
            <VideoPlayer url={videoURL} onClose = {onStopVideo} countdown = {enableCountdown} />
          </div>
        }
        {enableTrackInfo && <img
          className="artwork"
          src={image}
          alt={`track artwork for ${title} by ${artist}`}
        />}
       
        {enableTrackInfo &&  <h2 className="title">{title}</h2>}
        {enableTrackInfo && <h3 className="artist">{artist}</h3>} 
       
      </div>
      {enableControls && enableControl && <div className="audio-footer">
        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          //onPlayPauseClick={setIsPlaying}
          onPlayPauseClick={ () => playAudio(true)}
        />
        {enableProgress && <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />}
        </div>}
    </div>
    <div className="audio-player-bg"></div>
    <Backdrop
        trackIndex={trackIndex}
        activeColor={color}
        isPlaying={meetingInfo.enableLighting && isPlaying}
      />
    
    </div>
      </div>}
      {isShowPlayer && enableBubbles &&  meetingInfo.enableBubbles && <Bubbles />}
    </div>
    
  );
});

export default AudioPlayer;
