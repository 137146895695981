import { useEffect,useState} from "react";
import Backendless from 'backendless';

const Welcome = ({room,onContinue,meetingInfo}) => {
  const [name, setName] = useState(null);
  const [storedParticipant, setStoredParticipant] = useState(null);
  const enableNotificationMsg = window.showNotificationMessage;

  useEffect(() => {
    //get local participant based on meeting info
    if(meetingInfo){
      let participant = localStorage.getItem("participant_"+meetingInfo.objectId);
      participant = participant && participant!=="" ? JSON.parse(participant):participant;
      setStoredParticipant(participant);
      //Update Name
      if(participant && participant.name)
        setName(participant.name);
    }
  },[meetingInfo]);

  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const joinMeeting = () => {
    if(!name || name===""){
      alert("Name is required!");
    }else if(meetingInfo){
        onContinue()
        // Check if participant already joined
        if(storedParticipant && storedParticipant.objectId){
          //Call keep alive service
          keepAlive(storedParticipant);
        }else{
          //Add Participant to the room 
          const data = {name:name,meeting:meetingInfo.id};
          Backendless.Data.of('participants').save(data)
          .then(participant => {
              //store participant locally 
              localStorage.setItem("participant_"+meetingInfo.objectId, JSON.stringify(participant));
              //call keep alive directly to confirm user is online
              keepAlive(participant);
          })
          .catch(error => {
            console.log(error);
              alert("Please try again!");
          })
        }
    }
  }

  const callKeepAliveLater = (participant) => {
    //Call keep alive very 10 seconds
    setTimeout(function(){keepAlive(participant)},10000);
  }
  
  const keepAlive = (participant) => {
    const data = {
      "name": name,
      "objectId": participant.objectId
    }
    Backendless.APIServices.invoke('CodelessParticipantKeepAliveService', 'keepalive', data)
    .then(participant => {
       //Update stored participant
       localStorage.setItem("participant_"+meetingInfo.objectId, JSON.stringify(participant));
       setStoredParticipant(participant);
       //call keep alive
       callKeepAliveLater(participant);
    })
    .catch(error => {
      console.log(error);
      //keep calling keep alive
      callKeepAliveLater(participant);
    })

  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      joinMeeting();
    }
};

  return (
    <div className="welcome-holder">
        <div className="welcome-bg"></div>
        <div className="welcome-box">
        <div className="welcome-info">
            <h3 className="title">{meetingInfo!=null &&  meetingInfo.welcome ? meetingInfo.welcome : "Loading " + room + " room... "} </h3>
            <input type="text" name="name" onKeyPress={handleKeypress} placeholder="Please enter your full Name" maxLength="250" value={name?name:""} onChange={onNameChange} />
            <button onClick={joinMeeting}>Continue</button>
        </div>
        {enableNotificationMsg && <div className="notification-msg">You can enable browser notifications to get notified when meeting start!</div>}
        </div>
    </div>
  );
};

export default Welcome;
