import React, { useEffect, useState, useRef } from 'react';
import AudioPlayer from '../components/yoyuma-common/audioplayer/AudioPlayer';
import tracks from './tracks';
import Backendless from 'backendless';
import BreathCountDown from '../components/yoyuma-common/countdown/BreathCountDown';
import Background from '../components/yoyuma-common/ui/Background';
import Welcome from '../components/ui/Welcome';
//import { useCookies } from 'react-cookie';
import ReactHowler from 'react-howler';

const { REACT_APP_START_URL } = process.env;

const Meditation = () => {
  const [notify, setNotify] = useState(false);
  const MANUAL_MODE = "manual";
  const AUTO_MODE   =  "auto";
  let startDelay = 10;
  let startImmediaDelay = 5;
  const [meetingMode, setMeetingMode] = useState(AUTO_MODE);
  const [meetingStartReady, setMeetingStartReady] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [adPageConfig, setAdPageConfig] = useState(null);
  const [remainingSecs, setRemainingSecs] = useState(startDelay);
  const [breathCycle, setBreathCycle] = useState(1);
  const [isShowPlayer, setIsShowPlayer] = useState(false);
  const [isPlayAudio, setIsPlayAudio] = useState(false);
  const [roomInfo, setRoomInfo] = useState(null);
  const [isStarting, setIsStarting] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const audioPlayer = useRef(null);
  //const [cookies, setCookie] = useCookies(['notifiationsent']);
  
  const queryParams = new URLSearchParams(window.location.search);
  const slug = queryParams.get('slug');

  useEffect(() => {
    if(roomInfo==null){
      loadAdPageConfiguration();
    }
  });

  const getMeetingInfo = (roomInfo) => {
    //let whereClause = `slug = '${slug}'`;
    //let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
    //Backendless.Data.of( "meeting" ).find( queryBuilder )
    Backendless.APIServices.invoke('CodelessParticipantKeepAliveService', 'checkmeeting', slug)
    //.then( function( meetings ) {
    //  if(meetings && meetings[0]){
    // let meeting =  meetings[0];
    .then(meeting => {
      if(meeting){
        //setCookie('notifiationsent', false, { path: '/' });
        //
        //Update welcome message of the meeting based on  admin configuration 
        let welcomeMessage = roomInfo.welcome.replace("[ROOM_NAME]", meeting.welcome ? meeting.welcome : slug);
        meeting.welcome = welcomeMessage;
        //
        setMeetingInfo(meeting);
        window.meetingInfo = meeting;
        switch(meeting.status){
          case "STARTED":
          case "STARTING":
            //Nothing to do wait for user cliking on welcome
            break;
          default:
            if(meeting.start_time==null){
              //Wait till start by host
              setMeetingMode(MANUAL_MODE);
              //Listen to meeting start event
              addMessageStatusListner(meeting);
            }else{
              //Schedulle start time
              //TODO: get now from server 
              //get current date
              let cDate = new Date();
              //transform to UTC and get current time
              let utcDate = new Date(cDate.getUTCFullYear(), cDate.getUTCMonth(), cDate.getUTCDate(), cDate.getUTCHours(), cDate.getUTCMinutes(), cDate.getUTCSeconds() );
              let utcNow = utcDate.getTime();
              //calculate remaining seconds
              let utcDiff = (utcDate.getTimezoneOffset()*60*1000);
              let remaining = meeting.start_time - (utcNow - utcDiff);
              if(remaining>0){
                let seconds = Math.floor(remaining/1000.0);
                setRemainingSecs(seconds);
              }
            }
        }
        
      }else{
        alert("Meeting not found!");
      }
    })
    .catch( function( error ) {
        alert("Meeting not found!");
        console.log(error);
    });
  }

  const addMessageStatusListner = (meeting) => {
      //subscribe to meeting status channel listner
      let channel = Backendless.Messaging.subscribe( "meeting_status");
      //add selector to list to the required meeting only
      let selector = `meeting = '${meeting.objectId}'`; 
      channel.addMessageListener(selector,onMessage);
  }

  const onMessage = ( message ) => {
    const status = message.message;
    switch(status){
      case "STARTED":
         //Set a start delay of x seconds before starting the meeting when the event is received 
        startImmediaDelay = roomInfo && roomInfo.immediateStartDelay !=null ? parseInt(roomInfo.immediateStartDelay) : startImmediaDelay;
        setRemainingSecs(startImmediaDelay); 
        setMeetingMode(AUTO_MODE);
        notifyIsStarted();
        break;
      case "STARTING":
        setMeetingMode(AUTO_MODE);
        notifyStarting();
        break;
      default:
        //Keep waiting
        break;
    }
  }

  const notifyStarting = () => {
    if(!isStarting) {
      setIsStarting(true);
      setNotify(true);
      setTimeout(function(){setNotify(false);},5000);
      setTimeout(function(){showNotifications("Meeting is starting...!");},500);
    }
  }

  const notifyIsStarted = () => {
    if(!isStarted){
      setIsStarted(true);
      setNotify(true);
      setTimeout(function(){setNotify(false);},5000);
      setTimeout(function(){showNotifications("Meeting started!");},500);
    }
  }
  
  const onMeetingStarted = (link) => {
    if(isShowPlayer){
      startMeeting();
    }
  };

  const startMeeting = () => {
      setIsPlayAudio(false);
      //Show advertisement page if enabled
      if(adPageConfig.pageenable){
        setMeetingStartReady(true);
        //Add body event listner 
        document.body.addEventListener('click', joinMeeting);
        document.body.classList.add("click-body");
      }else{
        //Just redirect to the meeting
        window.location.href = meetingInfo.link;
      }
      //Show Meeting started notifications
      //setNotificationMsg("Meeting started!");
      //showNotifications();
  }

  const showNotifications = (notificationMsg) => {
    window.showNotification(meetingInfo ? meetingInfo.title : "", notificationMsg);
  }
 
  const joinMeeting = () => {
      //
      document.body.removeEventListener('click', joinMeeting);
      document.body.classList.remove("click-body");
      document.body.classList.remove("player-body");
      //
      //Open meeting
      window.open(meetingInfo.link);
      //show ad page content
      document.title = adPageConfig.title;
      document.body.innerHTML = adPageConfig.content;
      //TODO: Eval script is risky
      // eslint-disable-next-line
      eval(adPageConfig.script);
  }

    //Load Configuration
  const loadAdPageConfiguration = () => {
    const whereClause = `key = 'room_ad_page_info' || key = 'room_default_info' `;
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
    queryBuilder.addProperties('data');
    Backendless.Data.of( "system_configuration" ).find( queryBuilder )
    .then(function(info){
      //console.log(info);
      if(info && info.length>0){
        let defaultInfo = JSON.parse(info[0].data);
        setAdPageConfig(defaultInfo);
        if(info.length>1){
          let roomInfo = JSON.parse(info[1].data);
          setRoomInfo(roomInfo);
          //apply delay if configured
          if(roomInfo.startDelay !=null){
            startDelay = parseInt(roomInfo.startDelay);
            setRemainingSecs(startDelay);
            setBreathCycle(parseInt(roomInfo.breathCycle));
          }
          //
          //Get meeting info on configuration ready
          getMeetingInfo(roomInfo);
       }
       
      }
    })
    .catch( function( error ) {
        console.log(error);
    });
  }


  const onCountEnd = () => {
    if(meetingInfo){
      //Show notification and play sound 
      setMeetingMode(AUTO_MODE); 
      notifyIsStarted();
      setTimeout(function(){
        onMeetingStarted(meetingInfo.link);
      },500);
    }
  };

  const onWelcomeClickContinue = () => {
    audioPlayer.current.onClickContinue();
    if(meetingInfo){
      switch(meetingInfo.status){
        case "STARTED":
          //redirect to meeting
          if(adPageConfig.pageshow){
            startMeeting();
          }else{
           window.location.href = meetingInfo.link;
          }
          break;
        case "STARTING":
          addMessageStatusListner(meetingInfo);
          setIsShowPlayer(true)
          setIsPlayAudio(true);
          setRemainingSecs(roomInfo && roomInfo.startDelay != null ? parseInt(roomInfo.startDelay) : startDelay);
          break;
        default:
          setIsShowPlayer(true)
          setIsPlayAudio(true);
          break;
      }
    }
  }

  
  //setTimeout(function(){
        //setMeetingMode(AUTO_MODE); 
         //showNotifications();
  //},5000);

 
   return (
      <div> 
          
          <Background meetingInfo={meetingInfo} />

          <ReactHowler
            src='meeting-start.mp3'
            playing={notify}
          />
        
          {meetingInfo && <AudioPlayer
              ref={audioPlayer}
              mainTitle={meetingInfo?(meetingStartReady?adPageConfig.startdescription:meetingInfo.title):""}
              mainClassName={ meetingMode===AUTO_MODE?"manual-mode":"manual-mode"}  //auto-mode
              description={ meetingInfo && !meetingStartReady?meetingInfo.description:""}
              countdownPrompt={ roomInfo && meetingMode===AUTO_MODE && !meetingStartReady?
                ( 
                   <BreathCountDown opacity={roomInfo && roomInfo.breathsTransparancy?roomInfo.breathsTransparancy:100} key ="countdown" breathCycle = {breathCycle} remainingSecs = {remainingSecs} onCountEnd = {onCountEnd} />
                )
                :<span></span>
              }
              tracks={tracks} 
              enableTrackInfo = {false}
              enableProgress = {meetingInfo.enableMusic}
              enableControls = {meetingInfo.enableMusic}
              enableCountdown = {roomInfo && meetingMode===AUTO_MODE && !meetingStartReady}
              autoStart = {meetingInfo && isPlayAudio && meetingInfo.enableMusic}
              isShowPlayer = {isShowPlayer}
              meetingInfo={meetingInfo} 
              meetingStatus = {meetingInfo?meetingInfo.status:""}
              welcomePanel = {<Welcome room={slug} meetingInfo={meetingInfo} onContinue = {onWelcomeClickContinue}/>}
          />}
          {meetingInfo && !isShowPlayer && <div className="host-message"><a href={`${REACT_APP_START_URL}?slug=${meetingInfo.slug}`}><span>&#10148;</span> If you're the Host click here</a></div>}
      </div>
    );
  }

export default Meditation;